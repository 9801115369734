.legalNotice {
    position: fixed;
    z-index: 9999999;
    width: 100%;
    height: 100vh;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    &-container {
        display: flex;
        flex-direction: column;
        padding: 20px 50px;
        max-width: 1440px;
        margin: 0 auto;

        @include L {
            padding: 20px 40px;
        }

        @include M {
            padding: 20px 30px;
        }

        @include S {
            padding: 20px;
        }

        &-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 75px;

            @include L {
                margin-bottom: 50px;
            }

            @include M {
                margin-bottom: 30px;
            }

            @include S {
                margin-bottom: 15px;
            }

            &-title {
                font-size: 24px;
                letter-spacing: 1px;
                text-transform: uppercase;

                @include L {
                    font-size: 22px;
                }

                @include M {
                    font-size: 20px;
                }

                @include S {
                    font-size: 16px;
                }
            }

            &-close {
                font-size: 24px;
                cursor: pointer;

                @include S {
                    font-size: 22px;
                }
            }
        }

        &-middle {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;

            @include L {
                gap: 30px;
            }

            @include M {
                gap: 20px;
            }

            @include S {
                gap: 10px;
            }
           
            &-container {
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 80%;

                @include M {
                    width: 100%;
                }

                @include S {
                    gap: 10px;
                }

                &-title {
                    font-size: 20px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    @include M {
                        font-size: 18px;
                    }

                    @include S {
                        font-size: 14px;
                    }
                }

                &-text {
                    font-size: 18px;
                    letter-spacing: 1px;

                    @include M {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}