* {
    margin: 0;
    padding: 0;
}

body {
    box-sizing: border-box;
    font-family: 'Dosis', sans-serif;
    overflow-x: hidden;
}

.app {
    max-width: 1440px;
    margin: 0 auto;
}