.quote {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 50px;

    @include L {
        padding: 0 40px;
    }

    @include M {
        padding:  0 30px;
    }

    @include S {
        padding: 0 20px;
    }

    &-found {
        background-color: rgba($color: #000000, $alpha: 0.9);
        position: absolute;
        z-index: -1;
        width: 100vw;
        height: 100%;

        &-container {
            padding: 50px 0;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 25px;

            &-text {
                font-size: 28px;
                letter-spacing: 1px;

                @include M {
                    font-size: 22px;
                    text-align: center;
                }

                @include S {
                    font-size: 18px;
                }
            }

            &-link {
                text-decoration: none;
                color: white;
                padding: 10px 20px;
                background-color: rgba($color: red, $alpha: 0.9);
                border-radius: 5px;
                transition: all 350ms ease;

                &:hover {
                    transform: scale(1.1) translateY(5px);
                }

                @include S {
                    font-size: 14px;
                }
            }
        }
    }
}