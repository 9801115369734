.contactCompany {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    padding: 0 50px;

    @include L {
        padding: 0 40px;
    }

    @include M {
        padding: 0 30px;
    }

    @include S {
        padding: 0 20px;
    }

    &-top {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;

        &-title {
            font-size: 22px;
            letter-spacing: 1px;
            text-transform: uppercase;

            @include M {
                font-size: 18px;
            }

            @include S {
                font-size: 16px;
            }
        }

        &-subTitle {
            font-size: 20px;
            letter-spacing: 1px;

            @include M {
                font-size: 18px;
            }

            @include M {
                font-size: 16px;
            }
        }
    }

    &-middle {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        margin-top: 30px;
        row-gap: 25px;
        column-gap: 25px;

        @include M {
            display: flex;
            flex-direction: column;
            row-gap: 25px;
            column-gap: 0;
            align-items: center;
        }

        &-card {
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.1);
            border-radius: 5px;
            transition: all 350ms ease;

            @include S {
                width: 90%;
            }

            &:hover {
                transform: scale(1.05);
            }

            &-image {
                width: 100px;
            }

            &-title {
                font-size: 20px;
                text-transform: uppercase;
                margin-top: 20px;
                letter-spacing: 1px;

                @include M {
                    font-size: 18px;
                }

                @include S {
                    font-size: 16px;
                }
            }

            &-text {
                font-size: 18px;
                letter-spacing: 1px;
                margin-top: 5px;

                @include M {
                    font-size: 16px;
                }

                @include S {
                    font-size: 14px;
                }
            }

            &-link {
                color: white;
                padding: 10px 20px;
                border-radius: 5px;
                text-decoration: none;
                font-size: 18px;
                background-color: rgba($color: black, $alpha: 1);
                transition: all 350ms ease;
                box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.3);
                margin-top: 20px;

                @include M {
                    font-size: 16px;
                }

                @include S {
                    font-size: 14px;
                }
        
                &:hover {
                    transform: translateY(5px) scale(1.10);
                    background-color: red;
                }
            }
        }
    }
}