.serviceModal {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.6);

    &-container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        max-width: 1440px;
        width: 50%;
        padding: 25px 0;
        background-color: white;
        border-left: 5px solid rgba($color: red, $alpha: 0.7);
        transform: translateX(100%);
        transition: all 500ms ease;

        @include M {
            width: 100%;
            gap: 25px;
        }

        &-open {
            transform: translateX(0);
        }

        &-close {
            transform: translateX(100%);
        }

        &-top {
            display: flex;
            justify-content: space-between;
            padding: 0 50px;
            align-items: center;

            @include L {
                padding: 0 40px;
            }

            @include M {
                padding: 0 30px;
            }

            @include S {
                padding: 0 20px;
            }

            &-icon {
                width: 25px;
                height: 25px;
                cursor: pointer;
            }

            &-title {
                font-size: 20px;
                letter-spacing: 1px;
                text-transform: uppercase;

                @include M {
                    font-size: 18px;
                }

                @include S {
                    font-size: 16px;
                }
            }
        }

        &-middle {
            display: flex;
            flex-direction: column;
            padding: 0 50px;
            gap: 25px;

            @include L {
                padding: 0 40px;
            }

            @include M {
                padding: 0 30px;
                gap: 15px;
            }

            @include S {
                padding: 0 20px;
            }

            &-left {
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 15px;
                margin: auto 0;
                background-color: black;
                color: white;
                padding: 10px 15px;
                border-radius: 5px;
                cursor: pointer;
                transition: all 350ms ease;

                &:hover {
                    background-color: red;
                }
            }

            &-right {
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 15px;
                margin: auto 0;
                background-color: black;
                color: white;
                padding: 10px 15px;
                border-radius: 5px;
                cursor: pointer;
                transition: all 350ms ease;

                &:hover {
                    background-color: red;
                }
            }

            &-image {
                box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.3);
                border-radius: 5px;
                margin: 0 auto;
                height: 250px;
                margin-bottom: 15px;

                @include M {
                    height: 200px;
                    margin-bottom: 5px;
                }

                @include S {
                    height: 175px;
                }
            }

            &-icon {
                font-size: 23px;

                @include M {
                    font-size: 18px;
                }
            }

            &-text {
                font-size: 18px;

                @include M {
                    font-size: 16px;
                }
            }
        }

        &-bottom {
            display: flex;
            padding: 0 50px;
            flex-direction: column;
            gap: 15px;

            @include L {
                padding: 0 40px;
            }

            @include M {
                padding: 0 30px;
            }

            @include S {
                padding: 0 20px;
            }

            &-title {
                font-size: 20px;
                letter-spacing: 1px;
                text-transform: uppercase;

                @include S {
                    font-size: 18px;
                }
            }

            &-text {
                text-align: justify;
                font-size: 18px;
                letter-spacing: 1px;

                @include M {
                    font-size: 16px;
                }
            }
        }
    }
}