
@keyframes animateZoomBanner {
    0% {
        transform: scale(1);
    } 50% {
        transform: scale(1.3);
    } 100% {
        transform: scale(1);
    }
}

@keyframes animateLoader {
    0% {
        transform: translateY(-100%);
    } 30% {
        transform: translateY(0);
    } 70% {
        transform: translateY(0);
    } 100% {
        transform: translateY(100%);
    }
}