.loader {
    position: fixed;
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    animation: animateLoader 1900ms ease forwards;

    &-image {
        width: 20%;

        @include L {
            width: 20%;
        }

        @include M {
            width: 40%;
        }

        @include S {
            width: 40%;
        }
    }
}