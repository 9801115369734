.networkAbsolute {
    position: fixed;
    z-index: 99;
    bottom: 20px;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 10px;
    border-radius: 5px;
    background-color: rgba($color: #000000, $alpha: 1);

    &-link {
        display: flex;
        flex-direction: column;
        padding: 5px;
        border-radius: 5px;
        transition: all 350ms ease;

        &:hover {
            transform: scale(1.1);
        }

        
        &:first-child {
            background-color: white;
        }

        &:nth-child(2) {
            background-color: violet;
        }

        &:last-child {
            background-color: white;
        }
        
        &-image {
            width: 20px;
        }
    }
}