.bannerPages {
    position: relative;
    display: flex;
    justify-content: center;
    height: 700px;
    padding: 0 50px;
    margin-top: 151px;

    @include L {
        padding: 0 40px;
        height: 700px;
        margin-top: 200px;
    }

    @include M {
        padding: 0 30px;
        margin-top: 200px;
        height: 400px;
    }

    @include S {
        padding: 0 20px;
        margin-top: 200px;
        height: 300px;
    }

    &-fixed {
        overflow: hidden;
        width: 100vw;
        position: absolute;
        z-index: -1;
        height: 700px;

        @include L {
            height: 700px;
        }

        @include M {
            height: 400px;
        }

        @include S {
            height: 300px;
        }

        &-found {
            width: 100vw;
            height: 700px;
            filter: brightness(45%);
            animation: animateZoomBanner 30s ease-in-out infinite;

            @include L {
                height: 700px;
            }

            @include M {
                height: 400px;
            }

            @include S {
                height: 300px;
            }
        }
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        color: white;

        &-image {
            height: 150px;
            margin-bottom: 80px;

            @include L {
                height: 135px;
            }
    
            @include M {
                height: 80px;
                margin-bottom: 50px;
            }
    
            @include S {
                height: 60px;
                margin-bottom: 15px;
            }
        }

        &-title {
            margin-bottom: 10px;
            font-size: 35px;
            letter-spacing: 1px;
            text-transform: uppercase;

            @include L {
                font-size: 28px;
            }
    
            @include M {
                font-size: 20px;
                margin-top: 20px;
            }
    
            @include S {
                font-size: 18px;
            }
        }

        &-text {
            font-size: 24px;
            letter-spacing: 1px;
            text-align: center;

            @include L {
                font-size: 22px;
            }
    
            @include M {
                font-size: 16px;
            }
    
            @include S {
                font-size: 14px;
            }
        }
    }
}