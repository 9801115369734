.location {
    margin-top: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    color: white;
    height: max-content;
    position: relative;
    padding: 50px;

    @include L {
        padding: 50px 40px;
    }

    @include M {
        padding: 50px 30px;
    }

    @include S {
        padding: 50px 20px;
    }

    &-found {
        background-color: rgba($color: #000000, $alpha: 0.9);
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: -1;

        &-container {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            align-items: center;
            gap: 50px;

            @include L {
                flex-direction: column-reverse;
            }

            &-left {
                width: 65%;
                display: flex;
                flex-direction: column;
                gap: 35px;

                @include L {
                    width: 100%;
                    gap: 15px;
                }

                &-title {
                    font-size: 22px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    @include M {
                        font-size: 18px;
                    }
    
                    @include S {
                        font-size: 16px;
                    }
                }

                &-text {
                    font-size: 17px;
                    line-height: 1.6;
                    text-align: justify;

                    @include M {
                        font-size: 16px;
                    }

                    @include S {
                        font-size: 14px;
                    }

                    &-important {
                        font-size: 18px;

                        @include M {
                            font-size: 17px;
                        }

                        @include S {
                            font-size: 14px;
                        }
                    }
                }

                &-link {
                    box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.3);
                    width: fit-content;
                    padding: 10px 20px;
                    background-color: rgba($color: red, $alpha: 0.9);
                    border-radius: 5px;
                    text-decoration: none;
                    color: white;
                    margin: 0 auto;
                    transition: all 350ms ease;

                    &:hover {
                        transform: translateY(5px) scale(1.10);
                    }

                    @include S {
                        font-size: 14px;
                    }
                }
            }

            &-right {
                display: flex;
                width: 35%;
                justify-content: center;
                overflow: hidden;
                border-radius: 5px;

                @include L {
                    width: 65%;
                }

                @include M {
                    width: 100%;
                }

                &-image {
                    width: 100%;
                    border-radius: 5px;
                    animation: animateZoomBanner 20s ease-in-out infinite;
                }
            }
        }

        
    }
}