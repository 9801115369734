* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: "Dosis", sans-serif;
  overflow-x: hidden;
}

.app {
  max-width: 1440px;
  margin: 0 auto;
}

.header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transform: translateY(-100%);
  transition: all 500ms ease-in-out;
  height: 151px;
}
@media (max-width: 1024px) {
  .header {
    height: 200px;
  }
}
@media (max-width: 425px) {
  .header {
    height: 150px;
  }
}
.header-animate {
  transform: translateY(0);
}
.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.header-container-image {
  width: 75px;
  height: 75px;
}
@media (max-width: 425px) {
  .header-container-image {
    width: 50px;
    height: 50px;
  }
}
.header-container-title {
  font-size: 18px;
  font-family: "Lora", serif;
  font-weight: 900;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .header-container-title {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .header-container-title {
    font-size: 16px;
  }
}

.headerBis {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  z-index: 100;
  height: 151px;
}
@media (max-width: 1024px) {
  .headerBis {
    padding: 0 40px;
    height: 200px;
  }
}
@media (max-width: 768px) {
  .headerBis {
    padding: 0 30px;
  }
}
@media (max-width: 425px) {
  .headerBis {
    padding: 0 20px;
    height: 150px;
  }
}
.headerBis-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  transform: translateY(-100%);
  transition: all 500ms ease;
}
.headerBis-content-animate {
  transform: translateY(0);
}
@media (max-width: 1024px) {
  .headerBis-content {
    flex-direction: column;
    justify-content: center;
    gap: 25px;
  }
}
.headerBis-content-left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
.headerBis-content-left-image {
  width: 75px;
  height: 75px;
}
@media (max-width: 425px) {
  .headerBis-content-left-image {
    width: 50px;
    height: 50px;
  }
}
.headerBis-content-left-title {
  font-size: 20px;
  font-family: "Lora", serif;
  text-transform: uppercase;
  font-weight: 900;
}
@media (max-width: 1024px) {
  .headerBis-content-left-title {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .headerBis-content-left-title {
    font-size: 16px;
  }
}
.headerBis-content-right {
  display: flex;
  align-items: center;
}
.headerBis-content-right-image {
  width: 40px;
}
@media (max-width: 425px) {
  .headerBis-content-right-image {
    width: 30px;
  }
}
.headerBis-content-right-nav {
  display: flex;
  gap: 50px;
}
.headerBis-content-right-nav-link {
  font-size: 14px;
  text-decoration: none;
  color: black;
  transition: all 350ms ease;
  padding: 10px 0;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
@media (max-width: 1024px) {
  .headerBis-content-right-nav-link {
    font-size: 12px;
  }
}
.headerBis-content-right-nav-link:hover::before {
  content: "";
  height: 2px;
  width: 100%;
  background-color: red;
  position: absolute;
  bottom: 0;
}
.headerBis-content-right-nav-link-icon {
  height: 16px;
}
.headerBis-content-right-nav-link-active {
  transform: scale(1.1);
  font-weight: 600;
  position: relative;
}
.headerBis-content-right-nav-link-active::before {
  content: "";
  height: 2px;
  width: 100%;
  background-color: red;
  position: absolute;
  bottom: 0;
}
.headerBis-content-right-navMobile {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.bannerHome {
  position: relative;
  height: 700px;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 151px;
}
@media (max-width: 1024px) {
  .bannerHome {
    padding: 0 40px;
    height: 550px;
    margin-top: 200px;
  }
}
@media (max-width: 768px) {
  .bannerHome {
    padding: 0 30px;
    margin-top: 200px;
    height: 400px;
  }
}
@media (max-width: 425px) {
  .bannerHome {
    padding: 0 20px;
    margin-top: 150px;
    height: 300px;
  }
}
.bannerHome-fixed {
  overflow: hidden;
  width: 100vw;
  position: absolute;
  z-index: -1;
}
.bannerHome-fixed-found {
  width: 100vw;
  height: 700px;
  filter: brightness(45%);
  box-sizing: border-box;
  animation: animateZoomBanner 30s ease-in-out infinite;
}
@media (max-width: 1024px) {
  .bannerHome-fixed-found {
    height: 550px;
  }
}
@media (max-width: 768px) {
  .bannerHome-fixed-found {
    height: 400px;
  }
}
@media (max-width: 425px) {
  .bannerHome-fixed-found {
    height: 300px;
  }
}

.bannerHome-content {
  position: relative;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.bannerHome-content-image {
  height: 150px;
}
@media (max-width: 1024px) {
  .bannerHome-content-image {
    height: 135px;
  }
}
@media (max-width: 768px) {
  .bannerHome-content-image {
    height: 80px;
  }
}
@media (max-width: 425px) {
  .bannerHome-content-image {
    height: 60px;
  }
}
.bannerHome-content-title {
  font-size: 35px;
  margin-top: 40px;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 1024px) {
  .bannerHome-content-title {
    font-size: 28px;
  }
}
@media (max-width: 768px) {
  .bannerHome-content-title {
    font-size: 20px;
    margin-top: 20px;
  }
}
@media (max-width: 425px) {
  .bannerHome-content-title {
    font-size: 18px;
  }
}
.bannerHome-content-text {
  font-size: 26px;
  margin-top: 15px;
  text-align: center;
}
@media (max-width: 1024px) {
  .bannerHome-content-text {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .bannerHome-content-text {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .bannerHome-content-text {
    font-size: 14px;
  }
}
.bannerHome-content-link {
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  background-color: rgba(255, 0, 0, 0.9);
  transition: all 350ms ease;
  box-shadow: 0 0 6px red;
  margin-top: 40px;
}
@media (max-width: 768px) {
  .bannerHome-content-link {
    font-size: 16px;
    margin-top: 20px;
  }
}
@media (max-width: 425px) {
  .bannerHome-content-link {
    font-size: 14px;
  }
}
.bannerHome-content-link:hover {
  transform: translateY(5px) scale(1.1);
}

.aboutWork {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  padding: 0 50px;
}
@media (max-width: 1024px) {
  .aboutWork {
    padding: 0 40px;
    flex-direction: column-reverse;
    align-items: center;
    gap: 40px;
  }
}
@media (max-width: 768px) {
  .aboutWork {
    padding: 0 30px;
  }
}
@media (max-width: 425px) {
  .aboutWork {
    padding: 0 20px;
  }
}
.aboutWork-left {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  position: relative;
}
@media (max-width: 1024px) {
  .aboutWork-left {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .aboutWork-left {
    width: 90%;
  }
}
@media (max-width: 425px) {
  .aboutWork-left {
    width: 100%;
  }
}
.aboutWork-left::before {
  content: "";
  height: 50px;
  width: 50px;
  position: absolute;
  top: -10px;
  left: -21px;
  border-top: 4px solid red;
  border-left: 4px solid red;
  animation: animateZoomBanner 10s ease infinite;
}
@media (max-width: 425px) {
  .aboutWork-left::before {
    display: none;
  }
}
.aboutWork-left::after {
  content: "";
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: 0;
  right: -10px;
  border-bottom: 4px solid red;
  border-right: 4px solid red;
  animation: animateZoomBanner 10s ease infinite;
}
@media (max-width: 425px) {
  .aboutWork-left::after {
    display: none;
  }
}
.aboutWork-left-title {
  font-size: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .aboutWork-left-title {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .aboutWork-left-title {
    font-size: 15px;
  }
}
.aboutWork-left-text {
  line-height: 1.5;
  text-align: justify;
  font-size: 18px;
}
@media (max-width: 768px) {
  .aboutWork-left-text {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .aboutWork-left-text {
    font-size: 14px;
  }
}
.aboutWork-left-link {
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;
  background-color: black;
  transition: all 350ms ease;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
@media (max-width: 425px) {
  .aboutWork-left-link {
    font-size: 14px;
  }
}
.aboutWork-left-link:hover {
  transform: translateY(5px) scale(1.1);
  background-color: red;
}
.aboutWork-right {
  width: 40%;
  padding: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
@media (max-width: 1024px) {
  .aboutWork-right {
    justify-content: center;
    width: 100%;
    padding: 0;
  }
}
.aboutWork-right-image {
  width: 70%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
  position: relative;
}
@media (max-width: 1024px) {
  .aboutWork-right-image {
    width: 35%;
  }
}
@media (max-width: 768px) {
  .aboutWork-right-image {
    width: 50%;
  }
}
@media (max-width: 425px) {
  .aboutWork-right-image {
    width: 70%;
  }
}

.location {
  margin-top: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  color: white;
  height: -moz-max-content;
  height: max-content;
  position: relative;
  padding: 50px;
}
@media (max-width: 1024px) {
  .location {
    padding: 50px 40px;
  }
}
@media (max-width: 768px) {
  .location {
    padding: 50px 30px;
  }
}
@media (max-width: 425px) {
  .location {
    padding: 50px 20px;
  }
}
.location-found {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.location-found-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  gap: 50px;
}
@media (max-width: 1024px) {
  .location-found-container {
    flex-direction: column-reverse;
  }
}
.location-found-container-left {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 35px;
}
@media (max-width: 1024px) {
  .location-found-container-left {
    width: 100%;
    gap: 15px;
  }
}
.location-found-container-left-title {
  font-size: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .location-found-container-left-title {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .location-found-container-left-title {
    font-size: 16px;
  }
}
.location-found-container-left-text {
  font-size: 17px;
  line-height: 1.6;
  text-align: justify;
}
@media (max-width: 768px) {
  .location-found-container-left-text {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .location-found-container-left-text {
    font-size: 14px;
  }
}
.location-found-container-left-text-important {
  font-size: 18px;
}
@media (max-width: 768px) {
  .location-found-container-left-text-important {
    font-size: 17px;
  }
}
@media (max-width: 425px) {
  .location-found-container-left-text-important {
    font-size: 14px;
  }
}
.location-found-container-left-link {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 20px;
  background-color: rgba(255, 0, 0, 0.9);
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin: 0 auto;
  transition: all 350ms ease;
}
.location-found-container-left-link:hover {
  transform: translateY(5px) scale(1.1);
}
@media (max-width: 425px) {
  .location-found-container-left-link {
    font-size: 14px;
  }
}
.location-found-container-right {
  display: flex;
  width: 35%;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
}
@media (max-width: 1024px) {
  .location-found-container-right {
    width: 65%;
  }
}
@media (max-width: 768px) {
  .location-found-container-right {
    width: 100%;
  }
}
.location-found-container-right-image {
  width: 100%;
  border-radius: 5px;
  animation: animateZoomBanner 20s ease-in-out infinite;
}

.footer {
  margin-top: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  color: white;
  height: -moz-max-content;
  height: max-content;
  position: relative;
  padding: 25px 50px;
}
@media (max-width: 1024px) {
  .footer {
    padding: 25px 40px;
  }
}
@media (max-width: 768px) {
  .footer {
    padding: 25px 30px;
  }
}
@media (max-width: 425px) {
  .footer {
    padding: 25px 20px;
  }
}
.footer-found {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.footer-found-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footer-found-container-top {
  display: flex;
  justify-content: center;
}
@media (max-width: 1024px) {
  .footer-found-container-top {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
.footer-found-container-top-columns {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .footer-found-container-top-columns {
    width: 100%;
  }
}
.footer-found-container-top-columns-image {
  width: 150px;
  margin: auto;
}
@media (max-width: 1024px) {
  .footer-found-container-top-columns-image {
    width: 100px;
    margin: auto 0;
  }
}
.footer-found-container-top-columns-title {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .footer-found-container-top-columns-title {
    font-size: 16px;
  }
}
.footer-found-container-top-columns-link {
  margin-top: 10px;
  font-size: 18px;
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .footer-found-container-top-columns-link {
    font-size: 16px;
  }
}
.footer-found-container-top-columns-network {
  display: flex;
  margin-top: 20px;
  gap: 15px;
}
.footer-found-container-top-columns-network-link {
  text-align: center;
}
.footer-found-container-top-columns-network-link-image {
  width: 35px;
}
.footer-found-container-bottom {
  display: flex;
  margin-top: 35px;
}
@media (max-width: 768px) {
  .footer-found-container-bottom {
    flex-direction: column;
    align-items: center;
  }
}
.footer-found-container-bottom-left {
  display: flex;
  gap: 5px;
}
.footer-found-container-bottom-left-text {
  letter-spacing: 1px;
}
.footer-found-container-bottom-left-link {
  color: white;
}
.footer-found-container-bottom-center {
  margin: 0 auto;
  display: flex;
  gap: 10px;
}
@media (max-width: 768px) {
  .footer-found-container-bottom-center {
    flex-direction: column;
    align-items: center;
  }
}
.footer-found-container-bottom-center-copy {
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .footer-found-container-bottom-center-copy {
    text-align: center;
  }
}
.footer-found-container-bottom-center-mention {
  letter-spacing: 1px;
  text-decoration: underline;
  cursor: pointer;
}

.bannerPages {
  position: relative;
  display: flex;
  justify-content: center;
  height: 700px;
  padding: 0 50px;
  margin-top: 151px;
}
@media (max-width: 1024px) {
  .bannerPages {
    padding: 0 40px;
    height: 700px;
    margin-top: 200px;
  }
}
@media (max-width: 768px) {
  .bannerPages {
    padding: 0 30px;
    margin-top: 200px;
    height: 400px;
  }
}
@media (max-width: 425px) {
  .bannerPages {
    padding: 0 20px;
    margin-top: 200px;
    height: 300px;
  }
}
.bannerPages-fixed {
  overflow: hidden;
  width: 100vw;
  position: absolute;
  z-index: -1;
  height: 700px;
}
@media (max-width: 1024px) {
  .bannerPages-fixed {
    height: 700px;
  }
}
@media (max-width: 768px) {
  .bannerPages-fixed {
    height: 400px;
  }
}
@media (max-width: 425px) {
  .bannerPages-fixed {
    height: 300px;
  }
}
.bannerPages-fixed-found {
  width: 100vw;
  height: 700px;
  filter: brightness(45%);
  animation: animateZoomBanner 30s ease-in-out infinite;
}
@media (max-width: 1024px) {
  .bannerPages-fixed-found {
    height: 700px;
  }
}
@media (max-width: 768px) {
  .bannerPages-fixed-found {
    height: 400px;
  }
}
@media (max-width: 425px) {
  .bannerPages-fixed-found {
    height: 300px;
  }
}
.bannerPages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  color: white;
}
.bannerPages-container-image {
  height: 150px;
  margin-bottom: 80px;
}
@media (max-width: 1024px) {
  .bannerPages-container-image {
    height: 135px;
  }
}
@media (max-width: 768px) {
  .bannerPages-container-image {
    height: 80px;
    margin-bottom: 50px;
  }
}
@media (max-width: 425px) {
  .bannerPages-container-image {
    height: 60px;
    margin-bottom: 15px;
  }
}
.bannerPages-container-title {
  margin-bottom: 10px;
  font-size: 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .bannerPages-container-title {
    font-size: 28px;
  }
}
@media (max-width: 768px) {
  .bannerPages-container-title {
    font-size: 20px;
    margin-top: 20px;
  }
}
@media (max-width: 425px) {
  .bannerPages-container-title {
    font-size: 18px;
  }
}
.bannerPages-container-text {
  font-size: 24px;
  letter-spacing: 1px;
  text-align: center;
}
@media (max-width: 1024px) {
  .bannerPages-container-text {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .bannerPages-container-text {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .bannerPages-container-text {
    font-size: 14px;
  }
}

.service {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 0 50px;
}
@media (max-width: 1024px) {
  .service {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .service {
    padding: 0 30px;
  }
}
@media (max-width: 425px) {
  .service {
    padding: 0 20px;
  }
}
.service-top {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .service-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }
}
@media (max-width: 768px) {
  .service-top {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 425px) {
  .service-top {
    display: flex;
    flex-direction: column;
  }
}
.service-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  width: 18%;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  padding-bottom: 20px;
  transition: all 350ms ease;
}
@media (max-width: 1024px) {
  .service-top-container {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .service-top-container {
    width: 100%;
    height: 350px;
  }
}
@media (max-width: 425px) {
  .service-top-container {
    height: 400px;
  }
}
@media (max-width: 1024px) {
  .service-top-container-one {
    grid-column: 1;
    grid-row: 1;
  }
}
@media (max-width: 768px) {
  .service-top-container-one {
    grid-row: 1;
    grid-column: 1/2;
  }
}
@media (max-width: 1024px) {
  .service-top-container-two {
    grid-column: 2/3;
    grid-row: 1;
  }
}
@media (max-width: 768px) {
  .service-top-container-two {
    grid-column: 2/2;
    grid-row: 1;
  }
}
@media (max-width: 1024px) {
  .service-top-container-three {
    grid-column: 3/3;
    grid-row: 1;
  }
}
@media (max-width: 768px) {
  .service-top-container-three {
    grid-row: 2;
    grid-column: 1/2;
  }
}
@media (max-width: 1024px) {
  .service-top-container-four {
    grid-column: 1;
    grid-row: 2;
    transform: translateX(50%);
  }
}
@media (max-width: 768px) {
  .service-top-container-four {
    transform: none;
    grid-column: 2;
  }
}
@media (max-width: 1024px) {
  .service-top-container-five {
    grid-column: 3;
    grid-row: 2;
    transform: translateX(-50%);
  }
}
@media (max-width: 768px) {
  .service-top-container-five {
    grid-column: 2;
    grid-row: 3;
  }
}
@media (max-width: 425px) {
  .service-top-container-five {
    transform: none;
  }
}
.service-top-container:hover {
  transform: translateY(-10px);
}
.service-top-container-image {
  height: 75%;
  width: 100%;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .service-top-container-image {
    width: 100%;
  }
}
.service-top-container-title {
  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;
}
@media (max-width: 1024px) {
  .service-top-container-title {
    font-size: 18px;
  }
}
.service-top-container-button {
  border: none;
  font-family: "Dosis", sans-serif;
  background-color: black;
  padding: 10px 15px;
  color: white;
  font-size: 13px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
  transition: all 350ms ease;
}
.service-top-container-button:hover {
  transform: scale(1.1) translateY(-5px);
  background-color: red;
}
.service-middle {
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.service-middle-text {
  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;
}
@media (max-width: 768px) {
  .service-middle-text {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .service-middle-text {
    font-size: 16px;
  }
}
.service-middle-link {
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  background-color: black;
  transition: all 350ms ease;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
@media (max-width: 768px) {
  .service-middle-link {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .service-middle-link {
    font-size: 14px;
  }
}
.service-middle-link:hover {
  transform: translateY(5px) scale(1.1);
  background-color: red;
}

.quote {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0 50px;
}
@media (max-width: 1024px) {
  .quote {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .quote {
    padding: 0 30px;
  }
}
@media (max-width: 425px) {
  .quote {
    padding: 0 20px;
  }
}
.quote-found {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100%;
}
.quote-found-container {
  padding: 50px 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.quote-found-container-text {
  font-size: 28px;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .quote-found-container-text {
    font-size: 22px;
    text-align: center;
  }
}
@media (max-width: 425px) {
  .quote-found-container-text {
    font-size: 18px;
  }
}
.quote-found-container-link {
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  background-color: rgba(255, 0, 0, 0.9);
  border-radius: 5px;
  transition: all 350ms ease;
}
.quote-found-container-link:hover {
  transform: scale(1.1) translateY(5px);
}
@media (max-width: 425px) {
  .quote-found-container-link {
    font-size: 14px;
  }
}

.serviceModal {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.serviceModal-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 1440px;
  width: 50%;
  padding: 25px 0;
  background-color: white;
  border-left: 5px solid rgba(255, 0, 0, 0.7);
  transform: translateX(100%);
  transition: all 500ms ease;
}
@media (max-width: 768px) {
  .serviceModal-container {
    width: 100%;
    gap: 25px;
  }
}
.serviceModal-container-open {
  transform: translateX(0);
}
.serviceModal-container-close {
  transform: translateX(100%);
}
.serviceModal-container-top {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
}
@media (max-width: 1024px) {
  .serviceModal-container-top {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .serviceModal-container-top {
    padding: 0 30px;
  }
}
@media (max-width: 425px) {
  .serviceModal-container-top {
    padding: 0 20px;
  }
}
.serviceModal-container-top-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.serviceModal-container-top-title {
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .serviceModal-container-top-title {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .serviceModal-container-top-title {
    font-size: 16px;
  }
}
.serviceModal-container-middle {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  gap: 25px;
}
@media (max-width: 1024px) {
  .serviceModal-container-middle {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .serviceModal-container-middle {
    padding: 0 30px;
    gap: 15px;
  }
}
@media (max-width: 425px) {
  .serviceModal-container-middle {
    padding: 0 20px;
  }
}
.serviceModal-container-middle-left {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: auto 0;
  background-color: black;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 350ms ease;
}
.serviceModal-container-middle-left:hover {
  background-color: red;
}
.serviceModal-container-middle-right {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: auto 0;
  background-color: black;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 350ms ease;
}
.serviceModal-container-middle-right:hover {
  background-color: red;
}
.serviceModal-container-middle-image {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin: 0 auto;
  height: 250px;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .serviceModal-container-middle-image {
    height: 200px;
    margin-bottom: 5px;
  }
}
@media (max-width: 425px) {
  .serviceModal-container-middle-image {
    height: 175px;
  }
}
.serviceModal-container-middle-icon {
  font-size: 23px;
}
@media (max-width: 768px) {
  .serviceModal-container-middle-icon {
    font-size: 18px;
  }
}
.serviceModal-container-middle-text {
  font-size: 18px;
}
@media (max-width: 768px) {
  .serviceModal-container-middle-text {
    font-size: 16px;
  }
}
.serviceModal-container-bottom {
  display: flex;
  padding: 0 50px;
  flex-direction: column;
  gap: 15px;
}
@media (max-width: 1024px) {
  .serviceModal-container-bottom {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .serviceModal-container-bottom {
    padding: 0 30px;
  }
}
@media (max-width: 425px) {
  .serviceModal-container-bottom {
    padding: 0 20px;
  }
}
.serviceModal-container-bottom-title {
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 425px) {
  .serviceModal-container-bottom-title {
    font-size: 18px;
  }
}
.serviceModal-container-bottom-text {
  text-align: justify;
  font-size: 18px;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .serviceModal-container-bottom-text {
    font-size: 16px;
  }
}

.worksInProgress {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  padding: 0 50px;
}
@media (max-width: 1024px) {
  .worksInProgress {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .worksInProgress {
    padding: 0 30px;
  }
}
@media (max-width: 425px) {
  .worksInProgress {
    padding: 0 20px;
  }
}
.worksInProgress-image {
  height: 200px;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
@media (max-width: 425px) {
  .worksInProgress-image {
    height: 150px;
  }
}
.worksInProgress-text {
  font-size: 28px;
  letter-spacing: 1px;
  font-weight: 500;
  text-align: center;
}
@media (max-width: 768px) {
  .worksInProgress-text {
    font-size: 22px;
  }
}
.worksInProgress-subText {
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
}
@media (max-width: 768px) {
  .worksInProgress-subText {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .worksInProgress-subText {
    font-size: 14px;
  }
}

.contactCompany {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
}
@media (max-width: 1024px) {
  .contactCompany {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .contactCompany {
    padding: 0 30px;
  }
}
@media (max-width: 425px) {
  .contactCompany {
    padding: 0 20px;
  }
}
.contactCompany-top {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.contactCompany-top-title {
  font-size: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .contactCompany-top-title {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .contactCompany-top-title {
    font-size: 16px;
  }
}
.contactCompany-top-subTitle {
  font-size: 20px;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .contactCompany-top-subTitle {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .contactCompany-top-subTitle {
    font-size: 16px;
  }
}
.contactCompany-middle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  margin-top: 30px;
  row-gap: 25px;
  -moz-column-gap: 25px;
       column-gap: 25px;
}
@media (max-width: 768px) {
  .contactCompany-middle {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    -moz-column-gap: 0;
         column-gap: 0;
    align-items: center;
  }
}
.contactCompany-middle-card {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: all 350ms ease;
}
@media (max-width: 425px) {
  .contactCompany-middle-card {
    width: 90%;
  }
}
.contactCompany-middle-card:hover {
  transform: scale(1.05);
}
.contactCompany-middle-card-image {
  width: 100px;
}
.contactCompany-middle-card-title {
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 20px;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .contactCompany-middle-card-title {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .contactCompany-middle-card-title {
    font-size: 16px;
  }
}
.contactCompany-middle-card-text {
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 5px;
}
@media (max-width: 768px) {
  .contactCompany-middle-card-text {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .contactCompany-middle-card-text {
    font-size: 14px;
  }
}
.contactCompany-middle-card-link {
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  background-color: black;
  transition: all 350ms ease;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
}
@media (max-width: 768px) {
  .contactCompany-middle-card-link {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .contactCompany-middle-card-link {
    font-size: 14px;
  }
}
.contactCompany-middle-card-link:hover {
  transform: translateY(5px) scale(1.1);
  background-color: red;
}

.networkAbsolute {
  position: fixed;
  z-index: 99;
  bottom: 20px;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 10px;
  border-radius: 5px;
  background-color: black;
}
.networkAbsolute-link {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  transition: all 350ms ease;
}
.networkAbsolute-link:hover {
  transform: scale(1.1);
}
.networkAbsolute-link:first-child {
  background-color: white;
}
.networkAbsolute-link:nth-child(2) {
  background-color: violet;
}
.networkAbsolute-link:last-child {
  background-color: white;
}
.networkAbsolute-link-image {
  width: 20px;
}

@keyframes animateZoomBanner {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animateLoader {
  0% {
    transform: translateY(-100%);
  }
  30% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
.unfolding {
  padding: 0 50px;
  margin-top: 50px;
  position: relative;
}
@media (max-width: 1024px) {
  .unfolding {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .unfolding {
    padding: 0 30px;
  }
}
@media (max-width: 425px) {
  .unfolding {
    padding: 0 20px;
  }
}
.unfolding-top {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .unfolding-top {
    align-items: center;
  }
}
.unfolding-top-title {
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .unfolding-top-title {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .unfolding-top-title {
    font-size: 16px;
  }
}
.unfolding-top-subTitle {
  font-size: 18px;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .unfolding-top-subTitle {
    font-size: 16px;
    text-align: center;
  }
}
@media (max-width: 425px) {
  .unfolding-top-subTitle {
    font-size: 14px;
  }
}
.unfolding-middle {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}
@media (max-width: 768px) {
  .unfolding-middle {
    display: flex;
    flex-direction: column;
  }
}
.unfolding-middle-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
  align-items: center;
  gap: 25px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  transition: all 350ms ease;
}
.unfolding-middle-card:hover {
  background-color: rgba(255, 0, 0, 0.2);
}
.unfolding-middle-card-image {
  width: 50px;
}
@media (max-width: 425px) {
  .unfolding-middle-card-image {
    width: 40px;
  }
}
.unfolding-middle-card-title {
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 425px) {
  .unfolding-middle-card-title {
    font-size: 14px;
  }
}
.unfolding-middle-card-description {
  line-height: 1.5;
  text-align: justify;
}
@media (max-width: 425px) {
  .unfolding-middle-card-description {
    font-size: 14px;
  }
}
.unfolding-middle-one {
  grid-column: 1;
  grid-row: 1;
}
.unfolding-middle-two {
  grid-column: 2/3;
  grid-row: 1;
}
.unfolding-middle-three {
  grid-column: 3/3;
  grid-row: 1;
}
.unfolding-middle-four {
  grid-column: 1;
  grid-row: 2;
  transform: translateX(50%);
}
@media (max-width: 768px) {
  .unfolding-middle-four {
    transform: none;
  }
}
.unfolding-middle-five {
  grid-column: 3;
  grid-row: 2;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .unfolding-middle-five {
    transform: none;
  }
}
.unfolding-bottom {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 50px;
  align-items: center;
  position: relative;
  padding: 25px 0;
  color: white;
}
.unfolding-bottom-found {
  width: 100vw;
  position: absolute;
  z-index: -1;
  bottom: 0;
  border: 1px solid;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
}
.unfolding-bottom-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.unfolding-bottom-container-text {
  font-size: 30px;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .unfolding-bottom-container-text {
    font-size: 22px;
  }
}
@media (max-width: 425px) {
  .unfolding-bottom-container-text {
    font-size: 18px;
  }
}
.unfolding-bottom-container-link {
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  background-color: rgba(255, 0, 0, 0.9);
  transition: all 350ms ease;
  box-shadow: 0 0 6px red;
}
@media (max-width: 768px) {
  .unfolding-bottom-container-link {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .unfolding-bottom-container-link {
    font-size: 14px;
  }
}
.unfolding-bottom-container-link:hover {
  transform: translateY(5px) scale(1.1);
}

.loader {
  position: fixed;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  animation: animateLoader 1900ms ease forwards;
}
.loader-image {
  width: 20%;
}
@media (max-width: 1024px) {
  .loader-image {
    width: 20%;
  }
}
@media (max-width: 768px) {
  .loader-image {
    width: 40%;
  }
}
@media (max-width: 425px) {
  .loader-image {
    width: 40%;
  }
}

.legalNotice {
  position: fixed;
  z-index: 9999999;
  width: 100%;
  height: 100vh;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.legalNotice-container {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .legalNotice-container {
    padding: 20px 40px;
  }
}
@media (max-width: 768px) {
  .legalNotice-container {
    padding: 20px 30px;
  }
}
@media (max-width: 425px) {
  .legalNotice-container {
    padding: 20px;
  }
}
.legalNotice-container-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 75px;
}
@media (max-width: 1024px) {
  .legalNotice-container-top {
    margin-bottom: 50px;
  }
}
@media (max-width: 768px) {
  .legalNotice-container-top {
    margin-bottom: 30px;
  }
}
@media (max-width: 425px) {
  .legalNotice-container-top {
    margin-bottom: 15px;
  }
}
.legalNotice-container-top-title {
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .legalNotice-container-top-title {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .legalNotice-container-top-title {
    font-size: 20px;
  }
}
@media (max-width: 425px) {
  .legalNotice-container-top-title {
    font-size: 16px;
  }
}
.legalNotice-container-top-close {
  font-size: 24px;
  cursor: pointer;
}
@media (max-width: 425px) {
  .legalNotice-container-top-close {
    font-size: 22px;
  }
}
.legalNotice-container-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
@media (max-width: 1024px) {
  .legalNotice-container-middle {
    gap: 30px;
  }
}
@media (max-width: 768px) {
  .legalNotice-container-middle {
    gap: 20px;
  }
}
@media (max-width: 425px) {
  .legalNotice-container-middle {
    gap: 10px;
  }
}
.legalNotice-container-middle-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 80%;
}
@media (max-width: 768px) {
  .legalNotice-container-middle-container {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .legalNotice-container-middle-container {
    gap: 10px;
  }
}
.legalNotice-container-middle-container-title {
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .legalNotice-container-middle-container-title {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .legalNotice-container-middle-container-title {
    font-size: 14px;
  }
}
.legalNotice-container-middle-container-text {
  font-size: 18px;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .legalNotice-container-middle-container-text {
    font-size: 16px;
  }
}/*# sourceMappingURL=styles.css.map */