
// style header au démarrage de la page 

.header {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    transform: translateY(-100%);
    transition: all 500ms ease-in-out;
    height: 151px;

    @include L {
        height: 200px;
    }

    @include S {
        height: 150px;
    }

    &-animate {
        transform: translateY(0);
    }

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;

        &-image {
            width: 75px;
            height: 75px;
    
            @include S {
                width: 50px;
                height: 50px;
            }
        }
    
        &-title {
            font-size: 18px;
            font-family: 'Lora', serif;
            font-weight: 900;
            text-transform: uppercase;
    
            @include L {
                font-size: 18px;
            }
    
            @include S {
                font-size: 16px;
            }
        }

    }
}

// styles pour le header quand on commence à scroller

.headerBis {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    background-color: white;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
    z-index: 100;
    height: 151px;

    @include L {
        padding: 0 40px;
        height: 200px;
    }

    @include M {
        padding: 0 30px;
    }

    @include S {
        padding: 0 20px;
        height: 150px;
    }

    &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        transform: translateY(-100%);
        transition: all 500ms ease;

        &-animate {
            transform: translateY(0);
        }

        @include L {
            flex-direction: column;
            justify-content: center;
            gap: 25px;
        }

        &-left {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 5px;
            align-items: center;

            &-image {
                width: 75px;
                height: 75px;

                @include S {
                    width: 50px;
                    height: 50px;
                }
            }

            &-title {
                font-size: 20px;
                font-family: 'Lora', serif;
                text-transform: uppercase;
                font-weight: 900;

                @include L {
                    font-size: 18px;
                }

                @include S {
                    font-size: 16px;
                }
            }
        }

        &-right {
            display: flex;
            align-items: center;

            &-image {
                width: 40px;

                @include S {
                    width: 30px;
                }
            }
            
            &-nav {
                display: flex;
                gap: 50px;

                &-link {
                    font-size: 14px;
                    text-decoration: none;
                    color: black;
                    transition: all 350ms ease;
                    padding: 10px 0;
                    border-radius: 5px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    position: relative;

                    @include L {
                        font-size: 12px;
                    }

                    &:hover {
                        &::before {
                            content: "";
                            height: 2px;
                            width: 100%;
                            background-color: red;
                            position: absolute;
                            bottom: 0;
                        }
                    }

                    &-icon {
                        height: 16px;
                    }

                    &-active {
                        transform: scale(1.1);
                        font-weight: 600;
                        position: relative;

                        &::before {
                            content: "";
                            height: 2px;
                            width: 100%;
                            background-color: red;
                            position: absolute;
                            bottom: 0;
                        }
                    }
                }
            }
            &-navMobile {
                display: flex;
                justify-content: space-between;
                gap: 50px;
            }
        }
    }
}