.footer {
    margin-top: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    color: white;
    height: max-content;
    position: relative;
    padding: 25px 50px;

    @include L {
        padding: 25px 40px;
    }

    @include M {
        padding: 25px 30px;
    }

    @include S {
        padding: 25px 20px;
    }

    &-found {
        background-color: rgba($color: #000000, $alpha: 0.9);
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: -1;

        &-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            &-top {
                display: flex;
                justify-content: center;

                @include L {
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                }

                &-columns {
                    width: 33%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @include M {
                        width: 100%;
                    }

                    &-image {
                        width: 150px;
                        margin: auto;

                        @include L {
                            width: 100px;
                            margin: auto 0;
                        }
                    }

                    &-title {
                        font-size: 18px;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        letter-spacing: 1px;

                        @include M {
                            font-size: 16px;
                        }
                    }

                    &-link {
                        margin-top: 10px;
                        font-size: 18px;
                        color: white;
                        text-decoration: none;
                        letter-spacing: 1px;

                        @include M {
                            font-size: 16px;
                        }
                    }

                    &-network {
                        display: flex;
                        margin-top: 20px;
                        gap: 15px;
                        
                        &-link {

                            text-align: center;

                            &-image {
                                width: 35px;
                            }
                        }
                    }
                }
            }

            &-bottom {
                display: flex;
                margin-top: 35px;

                @include M {
                    flex-direction: column;
                    align-items: center;
                }

                &-left {
                    display: flex;
                    gap: 5px;

                    &-text {
                        letter-spacing: 1px;
                    }

                    &-link {
                        color: white;
                    }
                }

                &-center {
                    margin: 0 auto;
                    display: flex;
                    gap: 10px;

                    @include M {
                        flex-direction: column;
                        align-items: center;
                    }

                    &-copy {
                        letter-spacing: 1px;
                        
                        @include M {
                            text-align: center;
                        }
                    }

                    &-mention {
                        letter-spacing: 1px;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}